import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import Cookies from "universal-cookie";
class CalendarForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  isEditing() {
    const { calendar } = this.props;
    return !!(calendar||{}).id;
  }

  renderField(name,formikProps, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const required = properties['required'] || false
    const customValidation = properties['customValidation'] || false;
    const erroring = required && checkError && (!formikProps.values.calendar[name] || formikProps.values.calendar[name].length <= 0 || customValidation);

    return (
        <Field
          onChange={(e) => {
              const { calendar } = formikProps.values;
              calendar[name] = properties.formatValue ? properties.formatValue(e.target.value) : e.target.value
              formikProps.setFieldValue('calendar', calendar);
              this.setState({
                dirty: true,
              });
            }
          }
          type={properties['type']}
          disabled={properties['disabled']}
          component={properties['component']}
          className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`calendar[${name}]`}
        />
      )
  }


  renderSelectField(name, formikProps, options, renderOptions = {}) {
    const { checkError } = this.state;
    const customValidation = renderOptions['customValidation']||false;

    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['calendar'][name])[0]
    }catch (e) {

    }

    return (
      <Select
        options={options}
        classNamePrefix='select'
        defaultValue={renderOptions.defaultValue}
        value={options ? value : ""}
        onChange={(option) => {
          const { calendar } = formikProps.values;
          calendar[name] = option.value
          formikProps.setFieldValue('calendar', calendar)
        }}
        filterOption={this.customFilter}
      />
    )
  }
  
  formatSlug(value) {
    return (value||"").replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, '-')
  }

  getMonthsOptions() {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map(month => {
      return {
        value: month,
        label: month
      }
    })
  }

  getDayOfMonthOptions() {
    return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31].map(day => {
      return {
        value: day,
        label: day.toString().padStart(2, '0')
      }
    })
  }

  getYearOptions() {
    //PLEASE FIX DEAR GOD
    return [2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030, 2031, 2032, 2033, 2034, 2035].map(year => {
      return {
        value: year,
        label: year
      }
    })
  }

  getHourOptions() {
    return [12,1,2,3,4,5,6,7,8,9,10,11].map(hour => {
      return {
        value: hour,
        label: hour.toString().padStart(2, '0')
      }
    })
  }

  getMinuteOptions() {
    return [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59].map(minute => {
      return {
        value: minute,
        label: minute.toString().padStart(2, '0')
      }
    })
  }

  getMeridiumOptions() {
    return [
      {
        value: 'AM',
        label: 'AM'
      },
      {
        value: 'PM',
        label: 'PM'
      }
    ]
  }


  renderForm(formikProps) {
    const { error } = this.state;
    const { user } = this.props;
    return (
      <Form className="sg-link-form" >
        <div className="row">
          <div className="col-xs-12">
            <label>
              Title
            </label>
            {this.renderField('title', formikProps)}
          </div>
          <div className="col-xs-12">
            <label>
              Custom URL (optional)
            </label>
            <div class="input-prepend">
              <div class="input-group">
                <div class="input-group-addon">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Growl.link/c/i/</span>
                </div>
                {this.renderField('slug', formikProps, {
                  formatValue: this.formatSlug
                })}
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <label>
              Location
            </label>
            {this.renderField('location', formikProps)}
          </div>
          {formikProps.values.calendar.all_day_event ? null : (
              <div className="col-xs-12">
              <label>
                Timezone
              </label>
              {this.renderSelectField('timezone', formikProps, Intl.supportedValuesOf('timeZone').map(x=>{
                return {
                  value: x,
                  label: x
                }
              }))}
            </div>
          )}

          <div className="col-xs-12">
            <label>
              Description
            </label>
            {this.renderField('description', formikProps, {
              component: 'textarea'
            })}
          </div>
          <div className="col-xs-12" role="group" aria-labelledby="my-radio-group">
            <label> All Day Event? </label>
            {this.renderSelectField('all_day_event', formikProps, [
              {
                value: true,
                label: 'Yes'
              }
              ,{
                value: false,
                label: 'No'
              }
            ])}
          </div>
          <div className="col-xs-12">
            <br/><br/>
            <label>
              Start Date
            </label>
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Day
            </label>
            {this.renderSelectField('start_day', formikProps, this.getDayOfMonthOptions(), {
              type: 'number'
            })}
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Month
            </label>
            {this.renderSelectField('start_month', formikProps, this.getMonthsOptions())}
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Year
            </label>
            {this.renderSelectField('start_year', formikProps, this.getYearOptions())}
          </div>
          {formikProps.values.calendar.all_day_event ? "" : (
            <>
              <div className="col-xs-12 col-md-4">
                <label>
                  Hour
                </label>
                {this.renderSelectField('start_hour', formikProps, this.getHourOptions())}
              </div>
              <div className="col-xs-12 col-md-4">
                <label>
                  Minute
                </label>
                {this.renderSelectField('start_minute', formikProps, this.getMinuteOptions())}
              </div>
              <div className="col-xs-12 col-md-4">
                <label>
                  AM/PM
                </label>
                {this.renderSelectField('start_meridium', formikProps, this.getMeridiumOptions())}
              </div>
            </>
          )}
          <div className="col-xs-12">
            <br/><br/>
            <label>
              End Date
            </label>
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Day
            </label>
            {this.renderSelectField('end_day', formikProps, this.getDayOfMonthOptions(), {
              type: 'number'
            })}
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Month
            </label>
            {this.renderSelectField('end_month', formikProps, this.getMonthsOptions())}
          </div>
          <div className="col-xs-12 col-md-4">
            <label>
              Year
            </label>
            {this.renderSelectField('end_year', formikProps, this.getYearOptions())}
          </div>
          {formikProps.values.calendar.all_day_event ? "" : (
            <>
              <div className="col-xs-12 col-md-4">
                <label>
                  Hour
                </label>
                {this.renderSelectField('end_hour', formikProps, this.getHourOptions())}
              </div>
              <div className="col-xs-12 col-md-4">
                <label>
                  Minute
                </label>
                {this.renderSelectField('end_minute', formikProps, this.getMinuteOptions())}
              </div>
              <div className="col-xs-12 col-md-4">
                <label>
                  AM/PM
                </label>
                {this.renderSelectField('end_meridium', formikProps, this.getMeridiumOptions())}
              </div>
            </>
          )}

          <div className="col-xs-12">
            <br/><br/>
            <label>
              Tags (Seperate by comma)
            </label>
            {this.renderField('tag_list', formikProps)}
          </div>
          <div className="col-xs-12 sg-form-error">
            {error}
          </div>
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }

  renderButtons(formikProps) {
    const { user } = this.props;
    return (
      <div className="col-xs-12" style={{paddingTop: "20px"}}>
        <button onClick={formikProps.handleSubmit} type="button" class="sg-submit-button btn sg-button font-orangutan"> Save </button>
      </div>
    );
  }

  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { calendar } = this.props;
    return this.isEditing() ? `/admin/calendars/${calendar.id}` : `/admin/calendars`;
  }


  render() {
    const { user, calendar, onSave } = this.props;
    return (
      <Formik
        initialValues={{
          calendar: {
            title: calendar ? calendar.title : "",
            slug: calendar ? calendar.slug : "",
            description: calendar ? calendar.description : "",
            location: calendar ? calendar.location : "",
            timezone: calendar ? calendar.timezone : "America/Los_Angeles",
            all_day_event: calendar ? calendar.all_day_event||false : false,
            start_year: calendar ? calendar.start_year : "",
            start_month: calendar ? calendar.start_month : "",
            start_day: calendar ? calendar.start_day : "",
            end_year: calendar ? calendar.end_year : "",
            end_month: calendar ? calendar.end_month : "",
            end_day: calendar ? calendar.end_day : "",
            start_hour: calendar ? calendar.start_hour : "",
            start_minute: calendar ? calendar.start_minute : "",
            start_meridium: calendar ? calendar.start_meridium : "",
            end_hour: calendar ? calendar.end_hour : "",
            end_minute: calendar ? calendar.end_minute : "",
            end_meridium: calendar ? calendar.end_meridium : "",
            tag_list: calendar ? (calendar.tag_list||[]).join(", ") : "",  
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: JSON.stringify(values),
            headers: {
              "X-CSRF-Token": token,
              "Content-Type": 'application/json',
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              if (json.error == null) {
                if(onSave) {
                  onSave();
                }
              }
              else {
                this.setState({
                  error: json.error,
                  erroring: true
                })
              }
            });
        }}
        render={(formikProps) => (
          <div className="sg-link-form">
          {this.renderForm(formikProps)}
          </div>
        )}
      />
    );
  }
}

export default CalendarForm;
